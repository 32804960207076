@import '~antd/dist/antd.css';
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
body {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}
body,
#root,
html {
  height: 100%;
  background-color: #f1f2f5;
  color: #222222;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
main::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
/* ANTD TABLE FIX */
.ant-table-thead > tr > th {
  font-weight: 700 !important;
}
/* ANTD TAGS FIX */
.ant-tag {
  border-color: transparent !important;
}
/* ANTD ALERT FIX */
.ant-alert {
  border-color: transparent !important;
}
/* ANTD INLINE MENU TOOLTIP FIX */
.ant-menu-inline-collapsed-tooltip {
  display: none;
}
/* ANTD BUTTON OVERRIDE */
.ant-btn {
  border-radius: 5px;
  border: 0;
  outline: none;
  transition: background-color 250ms ease-in-out;
}
.ant-btn-default {
  border: 1px solid #222222;
  color: #222222;
  transition: color 250ms ease-in-out, border 250ms ease-in-out;
  --antd-wave-shadow-color: #222222;
}
.ant-btn-default:active,
.ant-btn-default:hover,
.ant-btn-default:focus {
  border: 1px solid #757575;
  color: #757575;
}
.ant-btn.secondary {
  background-color: #222222;
  color: #fff;
  --antd-wave-shadow-color: #333333;
}
.ant-btn.secondary:active,
.ant-btn.secondary:hover,
.ant-btn.secondary:focus {
  background-color: #333333;
  color: #fff;
  border-color: transparent;
}
.ant-btn.terciary {
  background-color: #189e6e;
  color: #fff;
  --antd-wave-shadow-color: #24d698;
}
.ant-btn.terciary:active,
.ant-btn.terciary:hover,
.ant-btn.terciary:focus {
  background-color: #189e6e;
  color: #fff;
  border-color: transparent;
}
.ant-btn-primary {
  background-color: #c92279;
  color: #fff;
  --antd-wave-shadow-color: #c92279;
}
.ant-btn-primary:active,
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: #a51d63;
  color: #fff;
  border-color: transparent;
}
.ant-btn[disabled] {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
/* ANTD BUTTON LINK FIX */
.ant-btn-link:active,
.ant-btn-link:hover,
.ant-btn-link:focus {
  color: unset !important;
}
/* REACT DATES CALENDAR FIX */
.DateInput_input__small {
  font-size: 14px !important;
  font-weight: 600 !important;
}
.SingleDatePicker_picker {
  z-index: 10;
}
.DateInput__small {
  width: 100% !important;
}
.DateInput__small > input {
  text-align: center;
  height: 30px !important;
  border-radius: 5px !important;
}
.DateInput_input__focused {
  border-bottom: 2px solid #e36a53 !important;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #e36a53 !important;
  border: 1px double #e36a53 !important;
  color: #fff;
  font-weight: bold !important;
}
.SingleDatePickerInput__withBorder {
  border-radius: 5px !important;
  border: 1px solid #dbdbdb;
}
.DateInput {
  border-radius: 5px !important;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner,
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #e36a53;
  color: #fff !important;
  font-weight: bold !important;
}
/* ANTD TIME PICKER FIX */
.ant-picker-ranges {
  display: flex;
  align-items: center;
  flex-flow: column;
}
.ant-picker-ranges > .ant-picker-ok,
.ant-picker-ranges > .ant-picker-ok > button {
  margin-left: 0px;
  width: 100%;
}
/* ANTD MODAL FIX */
.ant-modal-title {
  font-weight: bold;
  color: #fff !important;
  text-align: center !important;
}
.ant-modal-header {
  background: #222222 !important;
  color: #fff !important;
}
/* ANTD RADIO BUTTON FIX */
.ant-radio-button-wrapper-checked {
  background: #222222 !important;
  color: #fff !important;
  font-weight: 600 !important;
}
/* ANTD FORM ITEM ROW */
.ant-form-item {
  margin-bottom: 14px;
}
/* ANTD SPACE FIX */
.ant-space-align-center {
  justify-content: space-between;
  width: 100%;
}
/* ANTD INPUT HAS ERROR FIX */
.ant-form-item-explain.ant-form-item-explain-error {
  text-align: center;
}
/* ANTD NOTIFICATIONS FIX */
.ant-notification-hook-holder,
.ant-notification-notice {
  background: #222222e3 !important;
  backdrop-filter: blur(3px) !important;
}
.ant-notification-notice-message,
.ant-notification-close-icon {
  color: #fff !important;
}
/* ANTD DRAWER MASK FIX */
.ant-drawer.ant-drawer-open .ant-drawer-mask {
  backdrop-filter: blur(2px);
}
/* JSON DEBUG FIX */
.__json-pretty__ {
  padding: 7px;
  border-radius: 5px;
}
/* ANTD EMPTY FIX */
.ant-empty {
  margin: 8px 8px !important;
}
.ant-empty-description {
  color: #878a8e;
}
/* VIRTUAL TABLE FIX */
.v-l {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 5px 10px;
  border-right: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}
/* ANTD FORM ITEM LABEL FIX */
.ant-form-item-label > label {
  font-weight: 300;
  font-size: 12px;
  color: #00000091;
  height: 20px;
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  font-weight: 600;
  color: #fff;
  background-color: #44b7ff;
}
/* ANTD DESCRIPTIONS LABEL FIX */
.ant-descriptions-item-label {
  font-weight: 700;
}
.ant-descriptions-item-label > span > .anticon {
  margin-right: 5px;
}
.ant-descriptions-title > span > i {
  margin-right: 5px;
}
/* ANTD TAB FIX */
.ant-tabs-card > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab-active {
  border-left: 4px solid;
}
.ant-tabs-card > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab-active > .ant-tabs-tab-btn {
  font-weight: bold;
}
.d-none {
  display: none;
}
.rce-mbox {
  padding: 6px 9px 22px 9px;
}
.rce-mbox-time {
  bottom: -9px !important;
}
.rce-container-citem {
  margin-bottom: 15px;
}
.rce-mbox.rce-mbox-right {
  overflow: hidden;
}
.rce-mbox-photo--img {
  cursor: pointer;
}
.rce-citem-avatar img {
  width: auto !important;
  height: auto !important;
}
.MuiTableCell-root {
  border: 1px solid #e0e0e0;
  padding: 0px 10px !important;
}
.MuiTableCell-head {
  background: #f8f8f9 !important;
}
.MuiToolbar-gutters {
  background-color: #f1f2f5 !important;
  min-height: 50px !important;
}
.MuiTableFooter-root {
  background-color: #f1f2f5 !important;
}
.MuiTableCell-footer {
  border: none !important;
}
.MuiTablePagination-input {
  display: none !important;
}
.MuiPaper-elevation1 {
  padding-top: 11px !important;
  padding-bottom: 11px !important;
  background-color: #f1f2f5 !important;
  box-shadow: none !important;
  justify-content: flex-end !important;
}
.MuiPaper-elevation4 {
  box-shadow: none !important;
}
.MuiTableRow-root:hover {
  background-color: #edf6ff !important;
  cursor: pointer;
}
html [data-testid='Buscar-iconButton'],
[data-testid='Ver columnas-iconButton'] {
  text-align: center;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #c92279;
  border-radius: 4px;
  color: white;
}
html [data-testid='Ver columnas-iconButton'] {
  text-align: center;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #c92279;
  border-radius: 4px;
  color: white;
  margin: 0px 5px;
}
html [data-testid='Filtrar-iconButton'] {
  text-align: center;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #c92279;
  border-radius: 4px;
  color: white;
}
html [data-testid='Filtrar-iconButton']:hover {
  color: #2e2b2b;
  background: #fff;
  border-color: #2e2b2b;
}
html [data-testid='Buscar-iconButton']:hover {
  color: #2e2b2b;
  background: #fff;
  border-color: #2e2b2b;
}
html [data-testid='Ver columnas-iconButton']:hover {
  color: #2e2b2b;
  background: #fff;
  border-color: #2e2b2b;
}
.MuiInput-underline:after {
  border-bottom: 2px solid #c92279 !important;
}
.MuiCheckbox-colorPrimary.Mui-checked {
  color: #c92279 !important;
}
.MuiButton-textPrimary {
  color: #c92279 !important;
}
.MuiFormLabel-root.Mui-focused {
  color: #c92279 !important;
}
html [data-testid='pagination-back'] {
  height: 35px;
  width: 35px;
  background-color: white !important;
  border: 1px solid #ebebf3;
  box-sizing: border-box;
  border-radius: 4px;
}
html [data-testid='pagination-next'] {
  margin-left: 15px;
  height: 35px;
  width: 35px;
  background-color: white !important;
  border: 1px solid #ebebf3;
  box-sizing: border-box;
  border-radius: 4px;
}
.react-resizable-handle {
  display: none;
}
.ant-table-row {
  cursor: pointer;
}
